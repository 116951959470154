import React, { useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { graphql } from 'gatsby';
import { Grid, Row, Col } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { Layout, Seo } from '../components';
import { CompanyStats, VideoEmbed, CompanyEvent } from '../components';
import { Company } from '../utils';
import check from '../images/check.svg';
import styles from './company.module.css';
import '../components/layout/layout.css';

const cx = classnames.bind(styles);

interface QueryResult {
  strapi: {
    company: Company,
  },
};

export const query = graphql`
  query CompanyQuery($id: ID!) {
    strapi {
      company(id: $id) {
        description
        slug
        city
        houseNumber
        id
        name
        postalCode
        street
        logo {
          url
        }
        programItems(where: { tbEvent: { active: true } }) {
          id
          name
          startsAt
          location
          description
          programItemType {
            id
            name
            color
          }
        }
        studentProfile {
          id
          description
          usps {
            id
            usp
          }
        }
        generalStats {
          numberOfBranches
          numberOfCountries
          numberOfEmployees
        }
        jobStats {
          id
          numberOfInternships
          numberOfVacancies
        }
        jobsVideo {
          embedCode
          id
          title
          thumbnail {
            url
            formats
          }
        }
      }
    }
  }
`;

const hasGeneralStats = ({ generalStats }: Company) => {
  return generalStats.numberOfEmployees || generalStats.numberOfCountries || generalStats.numberOfBranches;
};

const hasJobStats = ({ jobStats }: Company) => {
  return jobStats.numberOfVacancies || jobStats.numberOfInternships;
};

const CompanyTemplate = ({ data }) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const company = useMemo(() => {
    return data?.strapi?.company || {};
  }, [data]);

  const statsWidth = () => {
    if (screens.md) return { flex: '160px' };
    return { span: 24 };
  };

  const programItems = company.programItems;

  const longNumberShortner = (num) => {
    const numString = num.toString();
    return numString.length > 5 ? `${numString.slice(0, -3)}k` : num;
  };

  const programItemTypes = useMemo(() => {
    if (!company) return [];
    return Object.entries(company.programItems.reduce((acc, current) => {
      return {
        ...acc,
        [current.programItemType.name]: current.programItemType.color,
      };
    }, {}));
  }, [company]);

  return (
    <Layout>
      <Seo title={company.name} />
      <div className={styles.company}>
        <section className={styles.logo}>
          <img src={company?.logo?.url} alt={company.name} />
        </section>

        <div className="container-sm">
          <div className={styles.topTags}>
            {programItemTypes && programItemTypes.map(([name, color], i) => {
              return <span key={i}><span className="tag" style={{ background: color }}>{name}</span>{' '}</span>
            })}
          </div>
        </div>

        <section>
          <div className="container-sm">
            <Row gutter={20}>
              {screens.sm ? <Col flex="calc(100% - 160px)">
                <h3><span className="turquoise">{company.name}</span></h3>
                  <ReactMarkdown source={company.description} />
                </Col>
                : <Col span={24}><h3><span className="turquoise">{company.name}</span></h3>
                  <ReactMarkdown source={company.description} />
                </Col>
              }
              {hasGeneralStats(company) ?
                <Col className={styles.statistics} {...statsWidth()}>
                  <Row justify="center">
                    <Col flex="120px"><CompanyStats statName={"Werknemers"} value={longNumberShortner(company?.generalStats?.numberOfEmployees)} /></Col>
                    <Col flex="120px"><CompanyStats statName={"Landen"} value={company?.generalStats?.numberOfCountries} /></Col>
                    <Col flex="120px"><CompanyStats statName={"Branches"} value={company?.generalStats?.numberOfBranches} /></Col>
                  </Row>
                </Col> : null
              }
            </Row>
          </div>
        </section>

        <section>
          <div className="container-sm">
            <Row className={styles.video} justify="center" gutter={20}>
              <Col flex="auto">
                <div className={styles.videoCol}>
                  {company?.jobsVideo?.embedCode ?
                    <VideoEmbed
                      title={company?.jobsVideo?.title}
                      embedCode={company?.jobsVideo?.embedCode}
                      thumbnailUrl={company?.jobsVideo?.thumbnail?.formats?.droplet?.url}
                      width={434}
                      height={278} /> : null
                  }
                </div>
              </Col>
              {hasJobStats(company) ?
                <Col className={styles.jobs} {...statsWidth()}>
                  <Row justify="center">
                    <Col flex="160px"><CompanyStats large statName={"Vacatures"} value={company?.jobStats?.numberOfVacancies} /></Col>
                    <Col flex="160px"><CompanyStats large statName={"Stages"} value={company?.jobStats?.numberOfInternships} /></Col>
                  </Row>
                </Col> : null
              }
            </Row>
          </div>
        </section>

        <section>
          <div className="container">
            <h3 style={{ marginBottom: 0 }}><span className="turquoise">Activiteiten</span></h3>
            <Row justify="start" gutter={20}>
              {programItems && programItems.map(item => {
                return <Col key={item.id} style={{ marginTop: 20 }} md={12} lg={8}>
                  <CompanyEvent
                    programItemName={item.name}
                    startsAt={item.startsAt}
                    location={item.location}
                    description={item.description}
                    programItemTypeName={item.programItemType.name}
                    programItemTypeColor={item.programItemType.color}
                  />
                </Col>
              })}
            </Row>
          </div>
        </section>

        <section>
          <div className="container">
            <Row gutter={[56, 16]}>
              <Col lg={16}>
                <h3><span className="turquoise">Deze studenten zoeken wij</span></h3>
                <ReactMarkdown source={company?.studentProfile?.description} />
              </Col>
              <Col lg={8}>
                {company?.studentProfile?.usps?.map(usp => <div key={usp.usp}>
                  <p className={styles.usp}><span><img src={check} /></span>{usp.usp}</p>
                </div>)}
              </Col>
            </Row>
          </div>
        </section>

        <section>
          <div className="container">
            <Row className={styles.address} justify="center">
              <Col flex="320px">
                <h3><span className="turquoise">Hier kun je ons vinden</span></h3>
                <p><HomeOutlined />{company.street} {company.houseNumber}</p>
                <p>{company.postalCode} {company.city}</p>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default CompanyTemplate;
